import {ScreenState} from "../core/models/categories/screen-state.model";
import {Relation} from "../core/models/entities/entity/relation.model";

export const generalState: ScreenState = {
  id: 0,
  name: 'General View',
}

export const generalRelation: Relation = {
  name: "general",
  label: "General Information",
  position: 0
}

export const USER_TOKEN = 'user_token'
export const CURRENT_USER = 'current_user'
export const AUDIT_OVERLAY_ARROW_PADDING = 35;
export const TABLE__CUSTOM_ROWS_COUNT = 2 ;
export const TABLE__DEFAULT_PER_PAGE = 50;
